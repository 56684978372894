@font-face {
  font-family: 'pfbeausans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/pfbeausansproregularwebfont.eot');
  src: url(./fonts/pfbeausansproregularwebfont.eot?#iefix) format('embedded-opentype'),url(./fonts/pfbeausansproregularwebfont.woff2) format('woff2'),url(./fonts/pfbeausansproregularwebfont.woff) format('woff'),url(./fonts/pfbeausansproregularwebfont.ttf) format('truetype'),url(./fonts/pfbeausansproregularwebfont.svg#pf_beausans_probold) format('svg')
}

/* -light */
@font-face {
  font-family: 'pfbeausans';
  font-style: normal;
  font-weight: 100;
  src: url(./fonts/pfbeausansprolightwebfont.eot);
  src: url(./fonts/pfbeausansprolightwebfont.eot?#iefix) format('embedded-opentype'),url(./fonts/pfbeausansprolightwebfont.woff2) format('woff2'),url(./fonts/pfbeausansprolightwebfont.woff) format('woff'),url(./fonts/pfbeausansprolightwebfont.ttf) format('truetype'),url(./fonts/pfbeausansprolightwebfont.svg#pf_beausans_probold) format('svg')
}

/* -thin */
@font-face {
  font-family: 'pfbeausans';
  font-style: normal;
  font-weight: 200;
  src: url(./fonts/pfbeausansprothinwebfont.eot);
  src: url(./fonts/pfbeausansprothinwebfont.eot?#iefix) format('embedded-opentype'),url(./fonts/pfbeausansprothinwebfont.woff2) format('woff2'),url(./fonts/pfbeausansprothinwebfont.woff) format('woff'),url(./fonts/pfbeausansprothinwebfont.ttf) format('truetype')
}

/* -semibold */
@font-face {
  font-family: 'pfbeausans';
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/pfbeausansprosemiboldwebfont.eot);
  src: url(./fonts/pfbeausansprosemiboldwebfont.eot?#iefix) format('embedded-opentype'),url(./fonts/pfbeausansprosemiboldwebfont.woff2) format('woff2'),url(./fonts/pfbeausansprosemiboldwebfont.woff) format('woff'),url(./fonts/pfbeausansprosemiboldwebfont.ttf) format('truetype'),url(./fonts/pfbeausansprosemiboldwebfont.svg#pf_beausans_probold) format('svg')
}

/* -bold */
@font-face {
  font-family: 'pfbeausans';
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/pfbeausansproboldwebfont.eot);
  src: url(./fonts/pfbeausansproboldwebfont.eot?#iefix) format('embedded-opentype'),url(./fonts/pfbeausansproboldwebfont.woff2) format('woff2'),url(./fonts/pfbeausansproboldwebfont.woff) format('woff'),url(./fonts/pfbeausansproboldwebfont.ttf) format('truetype'),url(./fonts/pfbeausansproboldwebfont.svg#pf_beausans_probold) format('svg')
}